import { createContext, useEffect, useState } from 'react';
import { ethers, providers } from 'ethers';
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import { message } from 'antd';
import { ErrorHandling } from 'utils/errorHandling';
import Web3Modal from 'web3modal'


export const Context = createContext();
export const ContextProvider = ({children}) => {
  const [priceLoading, setLoading] = useState(false);
  const [account, setAccount] = useState('');
  const [web3Modal, setWeb3Modal] = useState(null)
  const [walletProvider, setWalletProvider] = useState(null)
  const [isTrustWallet, setIsTrustWallet] = useState(false || localStorage.getItem('wallet') === 'walletconnect');
  const [selectedToken, setSelectedToken] = useState('bnb');
  const [selectedOffer, setSelectedOffer] = useState(0);
  const [selectedTokenBalance, setSelectedTokenBalance] = useState(0);
  const [selectedTokenPrice, setSelectedTokenPrice] = useState(1);
  const [hasJoined, setHasJoined] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const [hasRetweeted, setHasRetweeted] = useState(false);


  useEffect(() => {

    // initiate web3modal
    const providerOptions = {
   
      coinbasewallet: {
        package: CoinbaseWalletSDK, // Required
        options: {
          appName: "Papillon", // Required
         // infuraId: process.env.INFURA_KEY // Required unless you provide a JSON RPC url; see `rpc` below
        // infuraId: '73e0f71b0ca84c9399f4799beb7354b6',
        rpc: {
         56: 'https://bsc-dataseed.binance.org',
        //  97: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
        },
        chainId: 56,
        network: "binance", // --> this will be use to determine chain id 56
        }
      },
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          rpc: {
            56: 'https://bsc-dataseed.binance.org',
          // 97: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
          },
          network: "binance",
          chainId: 56
          //infuraId: '73e0f71b0ca84c9399f4799beb7354b6',
        }
      },
    };

    const newWeb3Modal = new Web3Modal({
      cacheProvider: true, // very important
      network: "binance",
      theme: "dark", // optional, 'dark' / 'light',
      providerOptions,
    });

    setWeb3Modal(newWeb3Modal)
  }, [])

  const disconnectWallet = async () => {
    await web3Modal.clearCachedProvider();
    setAccount("");
    setWalletProvider(null);
  };


  async function connectWallets() {
    try{
    const provider = await web3Modal.connect();
    
    addListeners(provider);

    const ethersProvider = new ethers.providers.Web3Provider(provider)
    //console.log('PROVIDER',provider);
    const userAddress = await ethersProvider.getSigner().getAddress()
    setAccount(userAddress)
    setWalletProvider(ethersProvider)
  }
  catch (error) {
    ErrorHandling(error);
  }
  }


  async function addListeners(web3ModalProvider) {
  
    web3ModalProvider.on("accountsChanged", (accounts) => {
      window.location.reload()
    });
    
    // Subscribe to chainId change
    web3ModalProvider.on("chainChanged", (chainId) => {
      window.location.reload()
    });
  }
  
 //GET BNB BALANCE
 const getBBalance = async(addr) => {
  // if(!account) return false;
   try {
       let balance = await walletProvider.getBalance(addr);
       setSelectedTokenBalance(ethers.utils.formatEther(balance));
       // console.log('BALANCE',balance);
   } catch (error) {
    // console.log(error)
   }
 };

  setInterval(async () => {
     getBBalance(account);

   }, 3000);

  return(
    <Context.Provider
      value={{
        account,
        web3Modal,
        walletProvider,
        isTrustWallet,
        selectedToken,
        selectedOffer,
        selectedTokenPrice,
        selectedTokenBalance,
        priceLoading,
        setSelectedToken,
        setSelectedOffer,
        setSelectedTokenPrice,
        setLoading,
        connectWallets,
        disconnectWallet,
        hasJoined, 
        setHasJoined,
        isFollowing, 
        setIsFollowing,
        hasRetweeted, 
        setHasRetweeted
      }}
    >{children}</Context.Provider>
  )
}