import { ethers } from "ethers";
import abi from 'contract/presale.json';
import abi2 from 'contract/privatesale.json';
import { Signer } from "./useSigner";

export async function Contract(provider) {
  //PRESALE CONTRACT
  const contractAddress = '0x398b9Ee4f90270874CCFD3c9cD4037820cA8278E';
  const signer = await Signer(provider);

  const Contract = new ethers.Contract(
    contractAddress,
    abi,
    signer
  );

  return Contract;
}
export async function Contract2(provider) {
  //PRESALE CONTRACT
  const contractAddress = '0x8ff9b1AAf50633bE36F9257d7c80A287724EE96b';
  const signer = await Signer(provider);

  const Contract = new ethers.Contract(
    contractAddress,
    abi2,
    signer
  );

  return Contract;
}