import { useContext, useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { Contract } from 'utils/useContract';
import { ReadContract } from 'utils/readContract';
import { Contract2 } from 'utils/useContract';
import { ReadContract2 } from 'utils/readContract';
import "../../pages/index_files/2.a90d6219.chunk.css";
import "../../pages/index_files/main.f693515f.chunk.css";
import "../../pages/index_files/app.css";
import logo from "../../pages/index_files/logo.png";
import { Context } from 'context/contex';
import Spinner from 'react-spinkit';
import { Button, Col, Form, message, Row, Select } from 'antd';
import { Allowance } from 'utils/getAllowance';
import { Signer } from 'utils/useSigner';
import ModalComponent from "components/Modal";
import { InfoOutlined } from '@ant-design/icons';
import { ErrorHandling } from 'utils/errorHandling';

const stakingAddress = '0x8ff9b1AAf50633bE36F9257d7c80A287724EE96b';
//const stakingAddress = '0x398b9Ee4f90270874CCFD3c9cD4037820cA8278E';

function formatLocalTime(timestamp) {
  const date = new Date(timestamp * 1000); // convert timestamp to milliseconds
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZoneName: "short"
  };
  return date.toLocaleString(undefined, options);
}

function formatNumber(number) {
  return Number(number).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}


export  function Live() {
  const {
    account,
    walletProvider
  } = useContext(Context);
  const [live, setLive] = useState([]);
  const [loading, setLoading] = useState(false);
 // const [amount, setAmount] = useState(0);
  const [token, setToken] = useState('');
  const [wshow, setWshow] = useState(false);
  const [amount, setAmount] = useState(Array(live.length).fill(''));

  // Update the state variable for the specific input field
const updateAmount = (index, value) => {
  const newAmounts = [...amount];
  newAmounts[index] = value;
  setAmount(newAmounts);
}

  const getToken = async() => {
    try{
      const contract = await ReadContract2();
    
      const t = await contract.token();
      //console.log(hcap);
      setToken(t.toString());

    }
    catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getToken();
  }, []);

  const getLive = async() => {
    try{
      const contract = await ReadContract2();
      setLoading(true);
    
      const pool = await contract.getLivePools();
      console.log(pool.toString());
      setLive(pool);

    }
    catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    getLive();
  }, []);

  const checkAllowance = async (poolID, index) => {
    try {
      let allowance;
     allowance = await Allowance(token, stakingAddress, walletProvider);

      if (!Number(allowance._hex) || parseInt(ethers.utils.formatUnits(allowance._hex, 18)) < 10000000000) {
        approve(token, poolID, index);
        message.info('Please Approve to spend token!');
      } else {
        handleStake(poolID, index);
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function approve(tokenAddress, poolID, index) {
    try {
      let signer;
      let abi = [
        'function increaseAllowance(address _spender, uint256 addedValue) public returns (bool success)',
      ];

      setLoading(true);
       signer = await Signer(walletProvider);

      let TokenContract = new ethers.Contract(tokenAddress, abi, signer);

      const data = await TokenContract.increaseAllowance(
        stakingAddress,
        ethers.utils.parseUnits('10000000000', 18)
      );

      async function PendingApprove() {
        try {
          const provider = walletProvider;
          const result = await provider.getTransactionReceipt(data.hash);
          if (result === null) {
            setTimeout(() => {
              PendingApprove();
            }, 2000);
          } else if (result !== null) {
            handleStake(poolID, index);
          }
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      }

      setTimeout(() => {
        PendingApprove();
      }, 2000);
    } catch (error) {
      setLoading(false);
      ErrorHandling(error);
    }
  }

  const handleStake = async(id, index) => {
  
    try {
      let contract;
      setLoading(true);

      contract = await Contract2(walletProvider);

      const data = await contract.stake(id,
        ethers.utils.parseUnits(amount[index].toString(), 18),
        {from: account}
      );
       //
      async function Pending() {
       const provider = walletProvider;
        const result = await provider.getTransactionReceipt(data.hash);
        try {
          if (result === null) {
            setTimeout(() => {
              Pending();
            }, 2000);
          } else if (result !== null) {
            alert("Your tokens were successfully staked!");
            window.location.reload();
            setLoading(false);
          }
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      }

      setTimeout(() => {
        Pending();
      }, 2000);
    } catch (error) {
      ErrorHandling(error);
      setLoading(false);
    }
  };
 
  const stake = (poolID, index) => {
    if (!amount[index] || parseFloat(amount[index]) < 100) return alert('Invalid amount!');
    if(!account) return alert("Please connect to a wallet.");


   /* if (selectedToken === 'bnb') {
      handleOrderBNB();
    } else {*/
      checkAllowance(poolID, index);
   // }
  };

    return(
        <>
            <div className="hidden sm:grid sm:grid-cols-8 uppercase bg-darkGrayBg px-2 sm:px-5 py-4 text-xs  font-medium rounded-md mb-5">
        <p className="mx-2">Pool</p>
        <p className="mx-2">Start Dates</p>
        <p className="mx-2">Pool Size</p>
        <p className="mx-2">Total PAP staked</p>
        <p className="mx-2">Max Stake Amount</p>
        <p className="mx-2">Rewards (in %)</p>
        <p className="mx-2">------</p>
        <p className="text-right pr-5">Action</p>
      </div>
        {live.length > 0 ? live.map((item, index) => (
          <>
          {//parseInt(item[0].toString()) > 0 &&
        <div data-accordion-component="AccordionItem" className="accordion__item">
       
              <div data-accordion-component="AccordionItemHeading" role="heading" className="accordion__heading" aria-level={3}>
                <div className="accordion__button" id="accordion__heading-:re:" aria-disabled="false" aria-expanded="false" aria-controls="accordion__panel-:re:" tabIndex={0} data-accordion-component="AccordionItemButton">
                  <div className="sm:grid sm:grid-cols-8 bg-darkGrayBg px-2 sm:px-5 py-4 text-xs md:text-sm rounded-tr-md rounded-tl-md border-b border-gray-700 border-opacity-70 text-gray-100 items-center">
                    <p className=" mx-2 flex justify-between  sm:block"><span className="inline-block sm:hidden text-[#647581]">Pool:</span> {item[4].toString()} Days Staking Pool (Pool #{item[0].toString()})</p>
                    <p className="mx-2 flex justify-between  sm:block"><span className="inline-block sm:hidden text-[#647581]">Start Date:</span> {formatLocalTime(parseInt(item[3].toString()))}</p>
                    <p className="mx-2 flex justify-between  sm:block"><span className="inline-block sm:hidden text-[#647581]">Pool Size:</span><span className="inline-flex justify-normal items-center"><img className="w-4 mr-1" src={logo} width={26} height={26} alt="" /> {formatNumber(ethers.utils.formatUnits((item[2]._hex), 18))}
                    <br/>{formatNumber( parseFloat(ethers.utils.formatUnits((item[2]._hex), 18)) - parseFloat(ethers.utils.formatUnits((item[5]._hex), 18)))} left to fill</span></p>
                    <p className="text-left my-2  mx-2 flex justify-between  sm:block"> <span className="inline-block sm:hidden text-[#647581]">Totake Staked:</span> <span className="inline-flex justify-normal items-center"><img className="w-4 mr-1" src={logo} width={26} height={26} alt="" />{formatNumber(ethers.utils.formatUnits((item[5]._hex), 18))}</span></p>
                    <p className="text-left mx-2 my-2 flex justify-between  sm:block"> <span className="inline-block sm:hidden text-[#647581]">Max Stake Amount:</span> <span className="inline-flex justify-normal items-center"><img className="w-4 mr-1" src={logo} width={26} height={26} alt="" />{formatNumber(ethers.utils.formatUnits((item[1]._hex), 18))}</span></p>
                    <p className="mx-2 flex justify-between  sm:block"><span className="inline-block sm:hidden text-[#647581]">Rewards (in %):</span> {item[6].toString()}%</p>
                    <p className="mx-2 flex justify-between  sm:block"><span className="inline-block sm:hidden text-[#647581]">Stake your tokens:</span> 
                    <button type="button" onClick={() => updateAmount(index, ethers.utils.formatUnits((item[1]._hex), 18))} style={{color:'lightgreen'}}>Max</button>
                    <input placeholder="0.00" className="placeholder-gray-500 text-sm pl-4 1pr-40 rounded-[4px] block w-full h-14  focus:outline-none" type="number" value={amount[index]} onChange={(e) => updateAmount(index, e.target.value)} style={{background: '#fff',color:'#333'}}/></p>
                    <div className="sm:text-right flex justify-between sm:block md:px-0 px-2"><button className="px-5 m- py-3 text-sm text-white btnstake rounded-lg mr-2" onClick={() => stake(item[0].toString(), index)}>Stake</button></div>
                  </div>
                </div>
              </div>
              <div data-accordion-component="AccordionItemPanel" className="accordion__panel" id="accordion__panel-:re:" hidden />
              
            </div>
}
        
</>
            
             ))
             : 
             <>
             <Row justify="center">
             <Spinner name="circle" color="#fac66b" />
           <h2 className=" mx-2 flex1 justify-between1  sm:block">No live pools found at this time..</h2>
           <p>&nbsp;</p>
         </Row>
                   
           </>
}
{/*
<ModalComponent key={index}
    visible={wshow}
    onCancel={() => setWshow(false)}
    style={{backgroundColor: '#191970 !important'}}>
    <h2 style={{fontSize: '24px'}}><InfoOutlined style={{color: 'yellow'}} />STAKE PAP TOKENS FOR {item[4].toString()} Days</h2>
    <div>
    <p><input placeholder="How many tokens?" className="placeholder-gray-500 text-sm pl-4 pr-40 rounded-[4px] block w-full h-14  focus:outline-none" type="number" value={amount} onChange={(e) => setAmount(e.target.value)} style={{background: '#191970'}}/></p>
    <button _ngcontent-aey-c34="" className="btn btn-eth crypto-btn my-1 py-2 px-1 wd-45" style={{textAlign:"center !important", color: '#FFF'}} onClick={() => stake(item[0].toString())}> &nbsp; &nbsp; <span _ngcontent-aey-c34="">Stake {amount} PAP tokens</span></button>

    </div>
    </ModalComponent>*/}
        </>
    );
}

export  function Finished() {
  const {
    account,
    walletProvider
  } = useContext(Context);
  const [full, setFull] = useState([]);
  const [full2, setFull2] = useState([]);
  const [fullmerge, setFullmerge] = useState([]);
  const [loading, setLoading] = useState(false);

  const getPools = async() => {
    try{
      const contract = await ReadContract();
      setLoading(true);
    
      const pool = await contract.getPools();
      console.log(pool);
      let npool = pool.filter((num) => {
      return num[0].toString() != '0'
      });
      setFull(npool);
      console.log(npool);

    }
    catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    getPools();
  }, []);

  const getPools2 = async() => {
    try{
      const contract = await ReadContract2();
      setLoading(true);
    
      const pool = await contract.getPools();
      console.log(pool);
      let npool = pool.filter((num) => {
      return num[0].toString() != '0'
      });
      setFull2(npool);
      console.log(npool);

    }
    catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    getPools2();
  }, []);

  function mergeArrays(arr1, arr2) {
    let newarray;
    newarray = [...arr1, ...arr2];
    setFullmerge(newarray);
  }

  useEffect(() => {
    mergeArrays(full2, full);
  }, [full2, full]);
 
    return(
        <>

<div className="hidden sm:grid sm:grid-cols-8 uppercase bg-darkGrayBg px-2 sm:px-5 py-4 text-xs  font-medium rounded-md mb-5">
        <p className="mx-2">Pool</p>
        <p className="mx-2">Start Dates</p>
        <p className="mx-2">Pool Size</p>
        <p className="mx-2">Total PAP staked</p>
        <p className="mx-2">Max Stake Amount</p>
        <p className="mx-2">Rewards (in %)</p>
        <p className="mx-2">------</p>
        <p className="text-right pr-5">--------</p>
      </div>

     {fullmerge.length > 0 ? fullmerge.map((item, index) => (
          <>
{//parseInt(item[0].toString()) > 0 &&
        <div data-accordion-component="AccordionItem" className="accordion__item">
       
              <div data-accordion-component="AccordionItemHeading" role="heading" className="accordion__heading" aria-level={3}>
                <div className="accordion__button" id="accordion__heading-:re:" aria-disabled="false" aria-expanded="false" aria-controls="accordion__panel-:re:" tabIndex={0} data-accordion-component="AccordionItemButton">
                  <div className="sm:grid sm:grid-cols-8 bg-darkGrayBg px-2 sm:px-5 py-4 text-xs md:text-sm rounded-tr-md rounded-tl-md border-b border-gray-700 border-opacity-70 text-gray-100 items-center">
                    <p className=" mx-2 flex justify-between  sm:block"><span className="inline-block sm:hidden text-[#647581]">Pool:</span> {item[4].toString()} Days Staking Pool (Pool {item[0].toString()})</p>
                    <p className="mx-2 flex justify-between  sm:block"><span className="inline-block sm:hidden text-[#647581]">Start Date:</span> {formatLocalTime(parseInt(item[3].toString()))}</p>
                    <p className="mx-2 flex justify-between  sm:block"><span className="inline-block sm:hidden text-[#647581]">Pool Size:</span><span className="inline-flex justify-normal items-center"><img className="w-4 mr-1" src={logo} width={26} height={26} alt="" /> {formatNumber(ethers.utils.formatUnits((item[2]._hex), 18))}</span></p>
                    <p className="text-left my-2  mx-2 flex justify-between  sm:block"> <span className="inline-block sm:hidden text-[#647581]">Total PAP staked:</span> <span className="inline-flex justify-normal items-center"><img className="w-4 mr-1" src={logo} width={26} height={26} alt="" />{formatNumber(ethers.utils.formatUnits((item[5]._hex), 18))}</span></p>
                    <p className="text-left mx-2 my-2 flex justify-between  sm:block"> <span className="inline-block sm:hidden text-[#647581]">Max Stake Amount:</span> <span className="inline-flex justify-normal items-center"><img className="w-4 mr-1" src={logo} width={26} height={26} alt="" />{formatNumber(ethers.utils.formatUnits((item[1]._hex), 18))}</span></p>
                    <p className="mx-2 flex justify-between  sm:block"><span className="inline-block sm:hidden text-[#647581]">Rewards (in %):</span> {item[6].toString()}%</p>
                    <p className="mx-2 flex justify-between  sm:block"><span className="inline-block sm:hidden text-[#647581]">Stake tokens:</span> ------</p>
                    <div className="sm:text-right flex justify-between sm:block md:px-0 px-2">
                      --------</div>
                  </div>
                </div>
              </div>
              <div data-accordion-component="AccordionItemPanel" className="accordion__panel" id="accordion__panel-:re:" hidden />
              
            </div>
         
     }
        
</>
            
             ))
             : 
             <>
             <Row justify="center">
             <Spinner name="circle" color="#fac66b" />
           <h2 className=" mx-2 flex1 justify-between1  sm:block">No finished pools found at this time..</h2>
           <p>&nbsp;</p>
         </Row>
                   
           </>
}

          
        </>
    );

}
export  function YourStakes() {
  const {
    account,
    walletProvider
  } = useContext(Context);
  const [stakes, setStakes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [stake, setStake] = useState('');
  const [sdate, setSdate] = useState('');
  const [ddate, setDdate] = useState('');
  const [reward, setReward] = useState(0);
  const [poolID, setPoolID] = useState(-1);
  const [apy, setApy] = useState(-1);
  const [days, setDays] = useState(-1);
  const [modal, setModal] = useState(false);

//======================================2

  const [stakes2, setStakes2] = useState([]);
  const [stake2, setStake2] = useState('');
  const [sdate2, setSdate2] = useState('');
  const [ddate2, setDdate2] = useState('');
  const [reward2, setReward2] = useState(0);
  const [poolID2, setPoolID2] = useState(-1);
  const [apy2, setApy2] = useState(-1);
  const [days2, setDays2] = useState(-1);
  const [modal2, setModal2] = useState(false);
  const [withdrawn, setWithdrawn] = useState('false');


  const string = 'Kj9g2L4N8qV7y1pB5wR3Z6tF0uIiXcEhSxMfDmWlOaUzvQePbT';
  const currentUnixTime = Math.floor(Date.now() / 1000);
 
  const getPools = async(addr) => {
    try{
      const contract = await ReadContract();
      setLoading(true);
    
      const pool = await contract.getUserStakes(addr);
      console.log(pool);
      let npool = pool.filter((num) => {
      return num[0].toString() != '0' //&& num[0].toString() != '1'
      });
      setStakes(npool);
      console.log(npool);

    }
    catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    getPools(account);
  }, [account]);


  const getPools2 = async(addr) => {
    try{
      const contract = await ReadContract2();
      setLoading(true);
    
      const pool = await contract.getUserStakes(addr);
      console.log(pool);
      let npool = pool.filter((num) => {
      return num[0].toString() != '0'
      });
      setStakes2(npool);
      console.log(npool);

    }
    catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    getPools2(account);
  }, [account]);


  const getWithdrawn = async(addr, id) => {
    //if(id == -1 || !addr) return alert('INVALID');
    try{
      const contract = await ReadContract2();
    
      const pool = await contract.oldWithdrawn(addr, id);
      setWithdrawn(pool.toString());
     

    }
    catch (error) {
      console.log(error);
    }
  }


  useEffect(() => {
  //  if(stake > 0){
  getWithdrawn(account, poolID);
    //}
  }, [poolID]);
 


  const getStake = async(id, addr, apy, days) => {
    if(id == -1 || !addr || apy == -1 || days == -1) return false;
    try{
      const contract = await ReadContract();
      setLoading(true);
    
      const pool = await contract.getStake(id,addr);
      console.log(pool);
      setStake(pool[0].toString());
      setSdate(parseInt(pool[1].toString()));
      setDdate(parseInt(pool[2].toString()));
      setReward(getEarnings(pool[0].toString(), (apy - 8)));

    }
    catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    getStake(poolID, account, apy, days);
  }, [poolID, account, apy, days]);
 


  const getStake2 = async(id, addr, apy, days) => {
    if(id == -1 || !addr || apy == -1 || days == -1) return false;
    try{
      const contract = await ReadContract2();
      setLoading(true);
    
      const pool = await contract.getStake(id,addr);
      console.log(pool);
      setStake2(pool[0].toString());
      setSdate2(parseInt(pool[1].toString()));
      setDdate2(parseInt(pool[2].toString()));
     // setWithdrawn(Number(pool[3].toString()));
      console.log('WITHDRAWN', Number(pool[3].toString()))
      setReward2(getEarnings(pool[0].toString(), (apy - 8)));

    }
    catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    getStake2(poolID2, account, apy2, days2);
  }, [poolID2, account, apy2, days2]);
 

 


  useEffect(() => {
   if(modal === false){
    setReward(0);
    setStake(0);
   }
  }, [modal]);

  useEffect(() => {
   if(modal2 === false){
    setReward2(0);
    setStake2(0);
   }
  }, [modal2]);

  //GET EARNINGS

const getEarnings = (amount, reward) => {
  let earning;
  let toRelease;

  let cont = parseInt(ethers.utils.formatUnits(amount, 18));
  //console.log(parseInt(cont));
  
 
  //EARNINGS
  earning = (parseFloat(cont) * parseInt(reward)) / 100;
  //compoundEarn = (earning * apy) / 100;
  toRelease = cont + earning;
  
 return toRelease;
  }

  const setVals = (pid, apy, days) => {
     setPoolID(pid);
     setApy(apy);
     setDays(days);
     setModal(true);

  }

  const setVals2 = (pid, apy, days) => {
     setPoolID2(pid);
     setApy2(apy);
     setDays2(days);
     setModal2(true);

  }

  const unStake = async(id, amount) => {
   if(id == -1 || amount < 1) return alert('Something is loading. Tap the button again.');
    try {
      let contract;
      setLoading(true);

      contract = await Contract2(walletProvider);

      const data = await contract.withdraw(id,
        ethers.utils.parseUnits(amount.toString(), 18),
        {from: account}
      );
       //
      async function Pending() {
       const provider = walletProvider;
        const result = await provider.getTransactionReceipt(data.hash);
        try {
          if (result === null) {
            setTimeout(() => {
              Pending();
            }, 2000);
          } else if (result !== null) {
            alert("Your tokens were successfully unstaked!");
            window.location.reload();
            setLoading(false);
          }
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      }

      setTimeout(() => {
        Pending();
      }, 2000);
    } catch (error) {
      ErrorHandling(error);
      setLoading(false);
    }
  };


  const unStake2 = async(id, amount) => {
   if(id == -1 || amount < 1) return alert('Something is loading. Tap the button again.');
    try {
      let contract;
      setLoading(true);

      contract = await Contract2(walletProvider);

      const data = await contract.withdrawOld(id,
        ethers.utils.parseUnits(amount.toString(), 18),
        account,
      );
       //
      async function Pending() {
       const provider = walletProvider;
        const result = await provider.getTransactionReceipt(data.hash);
        try {
          if (result === null) {
            setTimeout(() => {
              Pending();
            }, 2000);
          } else if (result !== null) {
            alert("Your tokens were successfully unstaked!");
            window.location.reload();
            setLoading(false);
          }
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      }

      setTimeout(() => {
        Pending();
      }, 2000);
    } catch (error) {
      ErrorHandling(error);
      setLoading(false);
    }
  };



    return( 
        <>
        {/*console.log('WITHDRAWN', Number( withdrawn))*/}
        {account ? 
        <>
      <div className="hidden sm:grid sm:grid-cols-8 uppercase bg-darkGrayBg px-2 sm:px-5 py-4 text-xs  font-medium rounded-md mb-5">
        <p className="mx-2">Pool</p>
        <p className="mx-2">Start Date</p>
        <p className="mx-2">Pool Size</p>
        <p className="mx-2">Total PAP staked</p>
        <p className="mx-2">Max Stake Amount</p>
        <p className="mx-2">Rewards (in %)</p>
        <p className="mx-2">------</p>
        <p className="text-right pr-5">--------</p>
      </div>
<h1 style={{textAlign:'center'}}>RECENT STAKES</h1>
     {stakes2.length > 0 ? stakes2.map((item, index) => (
          <>
{parseInt(item[0].toString()) > 0 &&
        <div data-accordion-component="AccordionItem" className="accordion__item">
       
              <div data-accordion-component="AccordionItemHeading" role="heading" className="accordion__heading" aria-level={3}>
                <div className="accordion__button" id="accordion__heading-:re:" aria-disabled="false" aria-expanded="false" aria-controls="accordion__panel-:re:" tabIndex={0} data-accordion-component="AccordionItemButton">
                  <div className="sm:grid sm:grid-cols-8 bg-darkGrayBg px-2 sm:px-5 py-4 text-xs md:text-sm rounded-tr-md rounded-tl-md border-b border-gray-700 border-opacity-70 text-gray-100 items-center">
                    <p className=" mx-2 flex justify-between  sm:block"><span className="inline-block sm:hidden text-[#647581]">Pool:</span> {item[4].toString()} Days Staking Pool</p>
                    <p className="mx-2 flex justify-between  sm:block"><span className="inline-block sm:hidden text-[#647581]">Start Date:</span>
              
                     {formatLocalTime(item[3].toString())}</p>
                    <p className="mx-2 flex justify-between  sm:block"><span className="inline-block sm:hidden text-[#647581]">Pool Size:</span><span className="inline-flex justify-normal items-center"><img className="w-4 mr-1" src={logo} width={26} height={26} alt="" /> {formatNumber(ethers.utils.formatUnits((item[2]._hex), 18))}</span></p>
                    <p className="text-left my-2  mx-2 flex justify-between  sm:block"> <span className="inline-block sm:hidden text-[#647581]">Total PAP staked:</span> <span className="inline-flex justify-normal items-center"><img className="w-4 mr-1" src={logo} width={26} height={26} alt="" />{formatNumber( ethers.utils.formatUnits((item[5]._hex), 18))}</span></p>
                    <p className="text-left mx-2 my-2 flex justify-between  sm:block"> <span className="inline-block sm:hidden text-[#647581]">Max Stake Amount:</span> <span className="inline-flex justify-normal items-center"><img className="w-4 mr-1" src={logo} width={26} height={26} alt="" />{formatNumber(ethers.utils.formatUnits((item[1]._hex), 18))}</span></p>
                    <p className="mx-2 flex justify-between  sm:block"><span className="inline-block sm:hidden text-[#647581]">Rewards (in %):</span> {item[6].toString()}%</p>
                    <p className="mx-2 flex justify-between  sm:block"><span className="inline-block sm:hidden text-[#647581]"></span></p>
                    <div className="sm:text-right flex justify-between sm:block md:px-0 px-2">
                    <button className="px-5 m- py-3 text-sm text-white btnstake rounded-lg mr-2" onClick={() => setVals2(item[0].toString(), item[6].toString(), item[4].toString())}>Withdraw</button></div>
                  </div>
                </div>
              </div>
              <div data-accordion-component="AccordionItemPanel" className="accordion__panel" id="accordion__panel-:re:" hidden />
              
            </div>
         
     }
        
</>
            
             ))
             : 
             <>
             <Row justify="center">
             <Spinner name="circle" color="#fac66b" />
           <h2 className=" mx-2 flex1 justify-between1  sm:block">It is either your stakes are still loading or you have not staked in any RECENT pools.</h2>
           <p>&nbsp;</p>
         </Row>
                   
           </>
}


            </>
            :
            <>
              <div className="sm:grid sm:grid-cols-12 bg-darkGrayBg px-2 sm:px-5 py-4 text-xs md:text-sm rounded-tr-md rounded-tl-md border-b border-gray-700 border-opacity-70 text-gray-100 items-center" style={{textAlign: "center", background:"red"}}>
              <Row justify="center">
              <Spinner name="circle" color="#fac66b" />
            <p className=" mx-2 flex1 justify-between1  sm:block">Please connect a wallet.</p>
            <p>&nbsp;</p>
          </Row>
                    
                    </div>
            </>
        }

        {account ? 
        <>
 
<h1 style={{textAlign:'center'}}>OLD STAKES</h1>
     {stakes.length > 0 ? stakes.map((item, index) => (
          <>
{parseInt(item[0].toString()) > 0 &&
        <div data-accordion-component="AccordionItem" className="accordion__item">
       
              <div data-accordion-component="AccordionItemHeading" role="heading" className="accordion__heading" aria-level={3}>
                <div className="accordion__button" id="accordion__heading-:re:" aria-disabled="false" aria-expanded="false" aria-controls="accordion__panel-:re:" tabIndex={0} data-accordion-component="AccordionItemButton">
                  <div className="sm:grid sm:grid-cols-8 bg-darkGrayBg px-2 sm:px-5 py-4 text-xs md:text-sm rounded-tr-md rounded-tl-md border-b border-gray-700 border-opacity-70 text-gray-100 items-center">
                    <p className=" mx-2 flex justify-between  sm:block"><span className="inline-block sm:hidden text-[#647581]">Pool:</span> {item[4].toString()} Days Staking Pool</p>
                    <p className="mx-2 flex justify-between  sm:block"><span className="inline-block sm:hidden text-[#647581]">Start Date:</span>
              
                     {formatLocalTime(item[3].toString())}</p>
                    <p className="mx-2 flex justify-between  sm:block"><span className="inline-block sm:hidden text-[#647581]">Pool Size:</span><span className="inline-flex justify-normal items-center"><img className="w-4 mr-1" src={logo} width={26} height={26} alt="" /> {formatNumber(ethers.utils.formatUnits((item[2]._hex), 18))}</span></p>
                    <p className="text-left my-2  mx-2 flex justify-between  sm:block"> <span className="inline-block sm:hidden text-[#647581]">Total PAP staked:</span> <span className="inline-flex justify-normal items-center"><img className="w-4 mr-1" src={logo} width={26} height={26} alt="" />{formatNumber(ethers.utils.formatUnits((item[5]._hex), 18))}</span></p>
                    <p className="text-left mx-2 my-2 flex justify-between  sm:block"> <span className="inline-block sm:hidden text-[#647581]">Max Stake Amount:</span> <span className="inline-flex justify-normal items-center"><img className="w-4 mr-1" src={logo} width={26} height={26} alt="" />{formatNumber(ethers.utils.formatUnits((item[1]._hex), 18))}</span></p>
                    <p className="mx-2 flex justify-between  sm:block"><span className="inline-block sm:hidden text-[#647581]">Rewards (in %):</span> {item[6].toString()}%</p>
                    <p className="mx-2 flex justify-between  sm:block"><span className="inline-block sm:hidden text-[#647581]"></span></p>
                    <div className="sm:text-right flex justify-between sm:block md:px-0 px-2">
                    <button className="px-5 m- py-3 text-sm text-white btnstake rounded-lg mr-2" onClick={() => setVals(item[0].toString(), item[6].toString(), item[4].toString())}>Withdraw</button></div>
                  </div>
                </div>
              </div>
              <div data-accordion-component="AccordionItemPanel" className="accordion__panel" id="accordion__panel-:re:" hidden />
              
            </div>
         
     }
        
</>
            
             ))
             : 
             <>
             <Row justify="center">
             <Spinner name="circle" color="#fac66b" />
           <h2 className=" mx-2 flex1 justify-between1  sm:block">It is either your stakes are still loading or you have not staked in any pool.</h2>
           <p>&nbsp;</p>
         </Row>
                   
           </>
}


            </>
            :
            <>
              <div className="sm:grid sm:grid-cols-12 bg-darkGrayBg px-2 sm:px-5 py-4 text-xs md:text-sm rounded-tr-md rounded-tl-md border-b border-gray-700 border-opacity-70 text-gray-100 items-center" style={{textAlign: "center", background:"red"}}>
              <Row justify="center">
              <Spinner name="circle" color="#fac66b" />
            <p className=" mx-2 flex1 justify-between1  sm:block">Please connect a wallet.</p>
            <p>&nbsp;</p>
          </Row>
                    
                    </div>
            </>
        }

<ModalComponent
    visible={modal}
    onCancel={() => setModal(false)}>
    <h2 style={{fontSize: '24px'}}><InfoOutlined style={{color: 'yellow'}} />STAKING DETAILS</h2>
    <div>
      <p>{withdrawn == "false" && stake > 0 ? 'You have '+ethers.utils.formatUnits((stake), 18)+' tokens staked in this pool and they will be unlocked on '+formatLocalTime(ddate)
    : 'You have no stakes in this pool or they are still loading. RELEAD the page and try again if it is taking too long.'  
    }
      </p>
    <p>Estimated Rewards (+ tax) after {days} days: <input placeholder="Loading..." className="placeholder-gray-500 text-sm pl-4 pr-40 rounded-[4px] block w-full h-14  focus:outline-none" type="number" value={reward === 0 ? 'Loading...' : reward.toFixed(2)}  style={{background: '#d6c4c6'}} readOnly/></p>
    {ddate && currentUnixTime >= ddate && stake > 0 && withdrawn == "false" &&
    <button _ngcontent-aey-c34="" className="btn btn-eth crypto-btn my-1 py-2 px-1 wd-45" style={{textAlign:"center !important", color: '#FFF'}} onClick={() => unStake2(poolID, reward)}> &nbsp; &nbsp; <span _ngcontent-aey-c34="">Unstake your PAP tokens</span></button>
   
}

    </div>
    </ModalComponent>

<ModalComponent
    visible={modal2}
    onCancel={() => setModal2(false)}>
    <h2 style={{fontSize: '24px'}}><InfoOutlined style={{color: 'yellow'}} />STAKING DETAILS</h2>
    <div>
      <p>{stake2 > 0 ? 'You have '+ethers.utils.formatUnits((stake2), 18)+' tokens staked in this pool and they will be unlocked on '+formatLocalTime(ddate2)
    : 'You have no stakes in this pool.'  
    }
      </p>
    <p>Estimated Rewards (+ tax) after {days2} days: <input placeholder="Loading..." className="placeholder-gray-500 text-sm pl-4 pr-40 rounded-[4px] block w-full h-14  focus:outline-none" type="number" value={reward2 === 0 ? 'Loading...' : reward2.toFixed(2)}  style={{background: '#d6c4c6'}} readOnly/></p>
    {ddate2 && currentUnixTime >= ddate2 && stake2 > 0 &&
    <button _ngcontent-aey-c34="" className="btn btn-eth crypto-btn my-1 py-2 px-1 wd-45" style={{textAlign:"center !important", color: '#FFF'}} onClick={() => unStake(poolID2, reward2)}> &nbsp; &nbsp; <span _ngcontent-aey-c34="">Unstake your PAP tokens</span></button>
   
}

    </div>
    </ModalComponent>

        </>
    );
}



export  function Admin() {
  const {
  
    account,
    walletProvider
  } = useContext(Context);
  const [cancelID, setCancelID] = useState('');
  const [maxstake, setMaxstake] = useState('');
  const [poolsize, setPoolsize] = useState('');
  const [starttime, setStarttime] = useState('');
  const [days, setDays] = useState('');
  const [apy, setApy] = useState('');
  const [newowner, setNewowner] = useState('');
  const [owner, setOwner] = useState('');
  const [contractbalance, setContractbalance] = useState(0);
  const [totallocked, setTotallocked] = useState(0);
  const [balanceLP, setBalanceLP] = useState(0);
  const [multiply, setMultiply] = useState(0);
  const [mul, setMul] = useState('');


  const getPAPBalance = async(provider, addr, token) => {
    if(!provider) return;
     try {
       let abi = ["function balanceOf(address _owner) public view returns (uint256)",];

         
         let Contract = new ethers.Contract(
           token,
           abi,
           provider
         );
         const result = await Contract.balanceOf(addr)
         setBalanceLP(ethers.utils.formatEther(result._hex));
          //console.log(result);
     } catch (error) {
       console.log(error)
     }
   };

   useEffect(() => {
    getPAPBalance(walletProvider, '0xE37453d9879cFfdA70d5dCaA66Ce32817CccB5dd', '0x8f355b3e348B6dF3351102728fEDAA928E8F9bd3');
  }, [walletProvider]);

  const createPool = async() => {
    if(!maxstake || !poolsize || !starttime || !days || !apy) return alert('One or more fields not filled in. Please check.');
     try {
       let contract;
 
       contract = await Contract2(walletProvider);
 
       const data = await contract.createPool(ethers.utils.parseUnits(maxstake.toString(), 18),
         ethers.utils.parseUnits(poolsize.toString(), 18),
         starttime,
         days,
         apy,
         {from: account}
       );
        //
       async function Pending() {
        const provider = walletProvider;
         const result = await provider.getTransactionReceipt(data.hash);
         try {
           if (result === null) {
             setTimeout(() => {
               Pending();
             }, 2000);
           } else if (result !== null) {
             alert("This pool was successfully created!");
             window.location.reload();
           }
         } catch (error) {
           console.log(error);
         }
       }
 
       setTimeout(() => {
         Pending();
       }, 2000);
     } catch (error) {
       ErrorHandling(error);
     }
   };


  const cancelPool = async() => {
    if(!cancelID) return alert('Pool ID field not filled in. Please check.');
     try {
       let contract;
 
       contract = await Contract2(walletProvider);
 
       const data = await contract.cancelPool(cancelID,
         {from: account}
       );
        //
       async function Pending() {
        const provider = walletProvider;
         const result = await provider.getTransactionReceipt(data.hash);
         try {
           if (result === null) {
             setTimeout(() => {
               Pending();
             }, 2000);
           } else if (result !== null) {
             alert("This pool was successfully cancelled!");
             window.location.reload();
           }
         } catch (error) {
           console.log(error);
         }
       }
 
       setTimeout(() => {
         Pending();
       }, 2000);
     } catch (error) {
       ErrorHandling(error);
     }
   };


  const changeOwner = async() => {
    if(!account || account != owner) return alert('You are not allowed to call this function.');
     try {
       let contract;
 
       contract = await Contract2(walletProvider);
 
       const data = await contract.transferOwnership(newowner,
         {from: account}
       );
        //
       async function Pending() {
        const provider = walletProvider;
         const result = await provider.getTransactionReceipt(data.hash);
         try {
           if (result === null) {
             setTimeout(() => {
               Pending();
             }, 2000);
           } else if (result !== null) {
             alert("Ownership transfered!");
             window.location.reload();
           }
         } catch (error) {
           console.log(error);
         }
       }
 
       setTimeout(() => {
         Pending();
       }, 2000);
     } catch (error) {
       ErrorHandling(error);
     }
   };

   const changeMultiply = async() => {
    if(!mul) return alert('Enter a number first.');
     try {
       let contract;
 
       contract = await Contract2(walletProvider);
 
       const data = await contract.setMultiply(mul,
         {from: account}
       );
        //
       async function Pending() {
        const provider = walletProvider;
         const result = await provider.getTransactionReceipt(data.hash);
         try {
           if (result === null) {
             setTimeout(() => {
               Pending();
             }, 2000);
           } else if (result !== null) {
             alert("APY limit changed!");
             window.location.reload();
           }
         } catch (error) {
           console.log(error);
         }
       }
 
       setTimeout(() => {
         Pending();
       }, 2000);
     } catch (error) {
       ErrorHandling(error);
     }
   };


  const getTokens = async() => {
    if(!account || account != owner) return alert('You are not allowed to call this function.');
     try {
       let contract;
 
       contract = await Contract2(walletProvider);
 
       const data = await contract.takeOutRemainingTokens({from: account});
        //
       async function Pending() {
        const provider = walletProvider;
         const result = await provider.getTransactionReceipt(data.hash);
         try {
           if (result === null) {
             setTimeout(() => {
               Pending();
             }, 2000);
           } else if (result !== null) {
             alert("Tokens transfered to your wallet!");
             window.location.reload();
           }
         } catch (error) {
           console.log(error);
         }
       }
 
       setTimeout(() => {
         Pending();
       }, 2000);
     } catch (error) {
       ErrorHandling(error);
     }
   };

       
   const getOwner = async() => {
    try{
      const contract = await ReadContract2();
    
      const pool = await contract.owner();
     setOwner(pool.toString());

    }
    catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getOwner();
  }, []);


   const getMultiply = async() => {
    try{
      const contract = await ReadContract2();
    
      const pool = await contract.multiply();
     setMultiply(parseInt(pool.toString()));

    }
    catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getMultiply();
  }, []);


   const getBalance = async() => {
    try{
      const contract = await ReadContract2();
    
      const pool = await contract.getContractBalance({from: account});
     setContractbalance(ethers.utils.formatUnits((pool.toString()), 18));

    }
    catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getBalance();
  }, []);


   const getLocked = async() => {
   // if(!account || account != owner) return;
    try{
      const contract = await ReadContract2();
    
      const pool = await contract.totalLocked({from: account});
     setTotallocked(pool.toString());

    }
    catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getLocked();
  }, []);

return(
<>
<h2>ADMIN AREA</h2>
{account && account == owner ?
<>
<div>
<Row justify="center" align="middle">
           <h2 className="text-white mx-2 flex1 justify-between1  sm:block">CREATE POOL</h2>
           </Row>

           <Row justify="center">
           <Col md={12} lg={4} style={{color:'#000'}}>
          MAX STAKE:</Col> 
          <Col md={12} lg={8}>
          <input placeholder="0.00" className="placeholder-gray-500 text-sm pl-4 1pr-40 rounded-[4px] block w-full h-14  focus:outline-none" type="text" value={maxstake} onChange={(e) => setMaxstake(e.target.value)} style={{background: '#fff',color:'#333'}}/>
          </Col>
          </Row>

          <p>&nbsp;</p>
      
          <Row justify="center">
           <Col md={12} lg={4} style={{color:'#000'}}>
          POOL SIZE:</Col> 
          <Col md={12} lg={8}>
          <input placeholder="0.00" className="placeholder-gray-500 text-sm pl-4 1pr-40 rounded-[4px] block w-full h-14  focus:outline-none" type="text" value={poolsize} onChange={(e) => setPoolsize(e.target.value)} style={{background: '#fff',color:'#333'}}/>
          </Col>
          </Row>
          <p>&nbsp;</p>

          <Row justify="center">
           <Col md={12} lg={4} style={{color:'#000'}}>
          START TIME:</Col> 
          <Col md={12} lg={8}>
          <input placeholder="0.00" className="placeholder-gray-500 text-sm pl-4 1pr-40 rounded-[4px] block w-full h-14  focus:outline-none" type="text" value={starttime} onChange={(e) => setStarttime(e.target.value)} style={{background: '#fff',color:'#333'}}/>
          </Col>
          </Row>
          <p>&nbsp;</p>

          <Row justify="center">
           <Col md={12} lg={4} style={{color:'#000'}}>
          DAYS:</Col> 
          <Col md={12} lg={8}>
          <input placeholder="0.00" className="placeholder-gray-500 text-sm pl-4 1pr-40 rounded-[4px] block w-full h-14  focus:outline-none" type="text" value={days} onChange={(e) => setDays(e.target.value)} style={{background: '#fff',color:'#333'}}/>
          </Col>
          </Row>

          <p>&nbsp;</p>

          <Row justify="center">
           <Col md={12} lg={4} style={{color:'#000'}}>
          APY %:</Col> 
          <Col md={12} lg={8}>
           <span style={{color:'#000'}}> Current limit is {(100 * multiply) / 2}%</span>
            <br/>
          <input placeholder="0.00" className="placeholder-gray-500 text-sm pl-4 1pr-40 rounded-[4px] block w-full h-14  focus:outline-none" type="text" value={apy} onChange={(e) => setApy(e.target.value)} style={{background: '#fff',color:'#333'}}/>

          </Col>
          </Row>

          <p>&nbsp;</p>

          <Row justify="center">
          <Col span={10}>
                      <button className="px-5 m- py-3 text-sm text-white btnstake rounded-lg mr-2" onClick={() => createPool()}>Create Pool</button>
                      </Col>
                    </Row>

                    <p>&nbsp;</p>
          
</div>

<div>
<Row justify="center" align="middle">
           <h2 className="text-white mx-2 flex1 justify-between1  sm:block">CANCEL A POOL</h2>
           </Row>

           <Row justify="center">
           <Col md={12} lg={4} style={{color:'#000'}}>
          POOL ID:</Col> 
          <Col md={12} lg={8}>
          <input placeholder="0.00" className="placeholder-gray-500 text-sm pl-4 1pr-40 rounded-[4px] block w-full h-14  focus:outline-none" type="text" value={cancelID} onChange={(e) => setCancelID(e.target.value)} style={{background: '#fff',color:'#333'}}/>
          </Col>
          </Row>

          <p>&nbsp;</p>

          <Row justify="center">
          <Col span={10}>
                      <button className="px-5 m- py-3 text-sm text-white btnstake rounded-lg mr-2" onClick={() => cancelPool()}>Cancel Pool</button>
                      </Col>
                    </Row>

                    <p>&nbsp;</p>
          
</div>

<div>
<Row justify="center" align="middle">
           <h2 className="text-white mx-2 flex1 justify-between1  sm:block">TRANSFER OWNERSHIP</h2>
           </Row>

           <Row justify="center">
           <Col md={12} lg={4} style={{color:'#000'}}>
          NEW OWNER:</Col> 
          <Col md={12} lg={8}>
          <input placeholder="0x0...xyz" className="placeholder-gray-500 text-sm pl-4 1pr-40 rounded-[4px] block w-full h-14  focus:outline-none" type="text" value={newowner} onChange={(e) => setNewowner(e.target.value)} style={{background: '#fff',color:'#333'}}/>
          </Col>
          </Row>

          <p>&nbsp;</p>

          <Row justify="center">
          <Col span={10}>
            <p style={{color:'#fff'}}>Current owner is <br/>{owner}</p>
                      <button className="px-5 m- py-3 text-sm text-white btnstake rounded-lg mr-2" onClick={() => changeOwner()}>Transfer Ownership</button>
                      </Col>
                    </Row>

                    <p>&nbsp;</p>
          
</div>

<div>
<Row justify="center" align="middle">
           <h2 className="text-white mx-2 flex1 justify-between1  sm:block">CHANGE REWARD LIMIT</h2>
           </Row>

           <Row justify="center">
           <Col md={12} lg={4} style={{color:'#000'}}>
          NEW LIMIT:</Col> 
          <Col md={12} lg={8}>
          <input placeholder="0" className="placeholder-gray-500 text-sm pl-4 1pr-40 rounded-[4px] block w-full h-14  focus:outline-none" type="text" value={mul} onChange={(e) => setMul(e.target.value)} style={{background: '#fff',color:'#333'}}/>
          </Col>
          </Row>

          <p>&nbsp;</p>

          <Row justify="center">
          <Col span={10}>
            <p style={{color:'#fff'}}>Current limit is {multiply}</p>
                      <button className="px-5 m- py-3 text-sm text-white btnstake rounded-lg mr-2" onClick={() => changeMultiply()}>Change Limit</button>
                      </Col>
                    </Row>

                    <p>&nbsp;</p>
          
</div>


<div>
<Row justify="center" align="middle">
           <h2 className="text-white mx-2 flex1 justify-between1  sm:block">WITHDRAW TOKENS</h2>
           </Row>

           <Row justify="center">
        
          <Col md={12} lg={12} style={{color:'#000', fontWeight:'bolder'}}>
         Current contract balance is {formatNumber(contractbalance)} PAP and a total of {formatNumber(parseFloat(totallocked))} PAP tokens are locked.
          </Col>
          </Row>

          <p>&nbsp;</p>

          <Row justify="center">
          <Col span={10}>
         
                      <button className="px-5 m- py-3 text-sm text-white btnstake rounded-lg mr-2" onClick={() => getTokens()}>Withdraw balance</button>
                      </Col>
                    </Row>

                    <p>&nbsp;</p>
          
</div>
</>

:
<>
<div className="sm:grid sm:grid-cols-12 bg-darkGrayBg px-2 sm:px-5 py-4 text-xs md:text-sm rounded-tr-md rounded-tl-md border-b border-gray-700 border-opacity-70 text-gray-100 items-center" style={{textAlign: "center", background:"#8b4c1d"}}>
<Row justify="center">
<Spinner name="circle" color="#fac66b" />
<p className=" mx-2 flex1 justify-between1  sm:block">Please connect an ADMIN  wallet.</p>
<p>&nbsp;</p>
</Row>
      
      </div>
</>
}

</>

);



}