import { useContext, useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { Button, Col, Form, message, Row, Select } from 'antd';
import telegram from "../index_files/telegram.svg";
import twitter from "../index_files/twitter.svg";
import bg from "../index_files/bg.jpg";

import bnb from 'assets/bnb.png'

import {
  BtnContribute,
  //BtnSelect,
  CardStyled,
  Container,
  FormItem,
  InputStyled,
  Subtitle,
  //Text,
  //ModalStyled,
  //AlertStyled
} from './styled';
import {Live, Finished, YourStakes, Admin} from 'components/checks/stakes';
//import SelectOffer from 'components/SelectToken/offer';
import "../index_files/2.a90d6219.chunk.css";
import "../index_files/main.f693515f.chunk.css";
import "../index_files/app.css";

import { Context } from 'context/contex';

import logo from "../index_files/logo.png";
import { ReactComponent as Swap } from 'assets/swap.svg';

import { ErrorHandling } from 'utils/errorHandling';
import { Contract } from 'utils/useContract';
//import { Allowance } from 'utils/getAllowance';
//import { Signer } from 'utils/useSigner';
import Spinner from 'react-spinkit';
//import { ContractTrustWallet } from 'utils/useContractTrustwallet';
//import { SignerTrustWallet } from 'utils/useSignerTrustwallet';
//import { AllowanceTrustWallet } from 'utils/getAllowanceTrustWallet';
import { ReadContract } from 'utils/readContract';
import { ReadContract2 } from 'utils/readContract';
import CountdownTimer from '../../components/Countdown/CountdownTimer';
//import './App.css';
import arrow from 'assets/arrow.png';
import man from 'assets/man.png';
import dog from 'assets/dog.png';
//import ModalComponent from "components/Modal";
//import { InfoOutlined } from '@ant-design/icons'
import ConnectWallet from 'components/ConnectWallet';

export default function Home() {

  //GET QUERY STRING

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  var userVar = urlParams.get('ref');
  if(userVar == "" || userVar === null || userVar === undefined){
    userVar = "0x50C3bE5a358D384fBFED393af09E6837203c4512";
  }

  //PRESALE CONTRACT
  const contractAddress = '0xB07f76925db04CAf2fB50E168871B5a124c6e2b9';
  const {
    selectedToken,
    selectedTokenBalance,
    selectedTokenPrice,
    priceLoading,
    isTrustWallet,
    account,
    walletProvider,
    connectWallets
  } = useContext(Context);


  const [contribution, setContribution] = useState(0);
  const [contribution2, setContribution2] = useState(0);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [balance, setBalance] = useState(0);

  function formatNumber(number) {
    return Number(number).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  function handleTabClick(index) {
    setActiveTab(index);
  }

  const tabs = [
    {
      title: 'Live',
      content: <Live />,
    },
    {
      title: 'Finished',
      content: <Finished />,
    },
    {
      title: 'Your Stakes',
      content: <YourStakes />,
    },
    {
      title: 'Admin',
      content: <Admin />,
    },
  ];



  const getToken = async() => {
    try{
      const contract = await ReadContract();
    
      const t = await contract.token();
      //console.log(hcap);
      setToken(t.toString());
      //setCap(hcap[0][0]);

    }
    catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    getToken();
  }, []);

 
  const getContribution = async () => {
    if(!account) return false;
    try {
      const contract = await ReadContract();
      const r = await contract.functions.stats(account);
     //  console.log(add);

      setContribution(ethers.utils.formatUnits(r[0].toString(), 18));
    } catch (err) {
      ErrorHandling(err);
    }
  };

  useEffect(() => {
    getContribution();
  }, [account]);

  
  const getContribution2 = async () => {
    if(!account) return false;
    try {
      const contract = await ReadContract2();
      const r = await contract.functions.stats(account);
     //  console.log(add);

      setContribution2(ethers.utils.formatUnits(r[0].toString(), 18));
    } catch (err) {
      ErrorHandling(err);
    }
  };

  useEffect(() => {
    getContribution2();
  }, [account]);

  

  const copyLink = (link) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(link)
        .then(() => {
          alert("Copied to clipboard.");
        })
        .catch((err) => {
          console.error('Could not copy text: ', err);
        });
    } else {
      const input = document.createElement('input');
      input.setAttribute('value', link);
      document.body.appendChild(input);
      input.select();
      document.execCommand('copy');
      document.body.removeChild(input);
      alert("Copied to clipboard.");
    }
  }
  
  const getPAPBalance = async(addr, provider,token) => {
    if(!provider) return;
    if(!addr) return;
    if(!token) return;

     try {
      // let accounts;
       let abi = ["function balanceOf(address _owner) public view returns (uint256)",];

       //  accounts = await walletProvider.listAccounts();
    
         //TO DO: USE MAON NET
         //const provider =  ethers.getDefaultProvider('https://mainnet.infura.io/v3/73e0f71b0ca84c9399f4799beb7354b6');
         
         let Contract = new ethers.Contract(
           token,
           abi,
           provider
         );
         const result = await Contract.balanceOf(addr)
         setBalance(ethers.utils.formatEther(result._hex));
          //console.log(result);
     } catch (error) {
       console.log(error)
     }
   };

   useEffect(() => {
    getPAPBalance(account, walletProvider, token);
  }, [account, walletProvider, token]);

  return (
   <>
   
   <div className="App">
  <div className="absolute w-full" data-headlessui-state>
    <div className=" sm:px-6 relative  w-full 1z-40">
      <div className="no-pad-container px-3 mx-auto">
        <div className="flex justify-between items-center py-3 xl:justify-start xl:space-x-10">
          <div className="flex justify-start xl:w-0 xl:flex-1"><a href="https://papillontoken.com"><span className="sr-only" /><img className="max-w-[60px] md:max-w-[72px]" src={logo} alt="" /></a></div>
          <div className="flex items-center">
            <nav className="z-40 xl:flex xl:items-center">

              <div className="hiddensss md:flex items-center"><a href="https://t.me/papillontoken" target="_blank" rel="noreferrer" className="mx-2 inline-block w-6 opacity-50"><img className src={telegram} alt="" />
                </a>
                <a href="https://www.twitter.com/tokenpapillon" target="_blank" rel="noreferrer" className="mx-2 inline-block w-6 opacity-50"><img className src={twitter} alt="" /></a>
                <a href="https://discord.com/invite/4rRyg6EZ7t" target="_blank" rel="noreferrer" className="mx-2 inline-block w-6 opacity-50"><img className src="./index_files/discord.svg" alt="" /></a>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="w-full relative 1z-30 bg-no-repeat bg-contain paddingtop1">
    <div className="bg-black bg-opacity-40 p-3 hidden sm:block">
      <div className="relative 1z-30 px-4 md:px-0">
        <div className="container mx-auto">
          <div className="flex justify-between items-center">
            <div className="text-xl font-medium">Staking PAP </div>
            <div className="flex items-center relative 1z-30 ">
             
              
              <div className="text-xs">PAP contract address: {token} <span> <button type="button" className=" inline-block ml-3 md:mr-12" onClick={() => copyLink(token)}>
                    <svg width={19} height={19} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15.8333 7.125H8.70833C7.83388 7.125 7.125 7.83388 7.125 8.70833V15.8333C7.125 16.7078 7.83388 17.4167 8.70833 17.4167H15.8333C16.7078 17.4167 17.4167 16.7078 17.4167 15.8333V8.70833C17.4167 7.83388 16.7078 7.125 15.8333 7.125Z" stroke="white" strokeOpacity="0.6" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M3.95898 11.8749H3.16732C2.74739 11.8749 2.34466 11.7081 2.04773 11.4112C1.7508 11.1142 1.58398 10.7115 1.58398 10.2916V3.16659C1.58398 2.74666 1.7508 2.34393 2.04773 2.047C2.34466 1.75007 2.74739 1.58325 3.16732 1.58325H10.2923C10.7122 1.58325 11.115 1.75007 11.4119 2.047C11.7088 2.34393 11.8757 2.74666 11.8757 3.16659V3.95825" stroke="white" strokeOpacity="0.6" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </button></span></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="container mx-auto pt-16 mb-10 px-5 lg:px-5">
      <div className=''>
        <div>
          <div className="bg-black bg-opacity-40 rounded-2xl px-5 py-5 lg:py-10 sm:grid sm:grid-cols-2 sm:gap-9 mt-6">
            <div className="mb-12 sm:mb-0">
              <div>
                <h5 className="text-base lg:text-xl font-thin mb-2 lg:mb-3 text-white">Total Amount Staked</h5>
                <h5 className="text-xl lg:text-2xl font-medium flex items-center"><span className="mr-2 inline-flex justify-center items-center"><img className="w-4" src={logo} width={36} height={36} alt="" /> </span><span className="inline-block mr-5 text-white">{formatNumber(parseFloat(contribution) + parseFloat(contribution2))}</span></h5>
              </div>
            </div>

            <div className="inline-block md:inline-flex items-center justify-end">
              <div>
        
                <div className="mr-4 flex items-center font-semibold rounded-md">
                  PAP Balance 
                  <div><img className="w-5 h-5 sm:w-auto mr-1 ml-4" src={logo} width={26} height={26} alt="" /> </div>
                  <div className="opacity-75 font-normal">{parseFloat(balance).toFixed(2)}</div>
                </div>
              </div>
             <ConnectWallet/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="container mx-auto poolListing px-6 md:px-0">
      <div className="bg-grayCommonBg py-4 px-5 flex items-center rounded-md mb-4">
      {tabs.map((tab, index) => (
        <div key={index} className={`ttab-header px-4 md:px-10 py-3 text-xs md:text-base font-medium rounded-lg cursor-pointer ${activeTab === index ? 'active' : ''}`} onClick={() => handleTabClick(index)}> {tab.title}</div>

        ))}
       
      </div>

      
  

      <div data-accordion-component="Accordion" className="accordion">
        <div>
          <div className="text-center" />
          {tabs.map((tab, index) => (
          <div key={index}
          className={`ttab-content ${activeTab === index ? 'active' : 'inactive'}`}>

            {/*LIVE*/}
            {tab.content}

          </div>
           ))}
        </div>
      </div>{/*CLOSED*/}
    </div>
  </div>
  <div>
    <div className="bg-[#162128] p-4 text-sm text-[#718096] text-center ">© 2023 <a className="text-[#0788FF] text-sm inline-block" href="https://papillontoken.com">Papillon Token</a>. All rights reserved.</div>
  </div>
</div>

       
  
  
   </>
  
  
    );
 
}