import { ethers } from "ethers";
import abi from 'contract/presale.json';
import abi2 from 'contract/privatesale.json';

export async function ReadContract() {
  const contractAddress = '0x398b9Ee4f90270874CCFD3c9cD4037820cA8278E';
  const provider = ethers.getDefaultProvider('https://bsc-dataseed.binance.org/');
  //const provider = ethers.getDefaultProvider('https://data-seed-prebsc-1-s1.binance.org:8545/');
  const Contract = new ethers.Contract(
    contractAddress,
    abi,
    provider
  );

  return Contract;
}


export async function ReadContract2() {
  const contractAddress = '0x8ff9b1AAf50633bE36F9257d7c80A287724EE96b';
  const provider = ethers.getDefaultProvider('https://bsc-dataseed.binance.org/');
  //const provider = ethers.getDefaultProvider('https://data-seed-prebsc-1-s1.binance.org:8545/');
  const Contract = new ethers.Contract(
    contractAddress,
    abi2,
    provider
  );

  return Contract;
}
