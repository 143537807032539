import { ethers } from "ethers";

export async function Allowance(tokenAddress,spender,wProvider) {

  let abi = ["function allowance(address _owner, address _spender) public view returns (uint256)"];

  const provider = wProvider; 
  const accounts = await provider.listAccounts();
  let signer = provider.getSigner(accounts[0]);

  const contract = new ethers.Contract(tokenAddress, abi, signer);
  let allowance = await contract.allowance(accounts[0], spender);

  return allowance;
}