import styled from "styled-components"
import { Card, Input, Form, Collapse, Button } from 'antd';

export const Container = styled.div`
  max-width: 500px;
  min-height: calc(100vh - 120px);
  margin: auto;
  padding: 4rem 0;
  @media screen and (max-width: 500px) {
    padding: 20px 10px;
  }
`
export const CardStyled = styled(Card)`
  border: none;
  background-color: #504fd6;
  border-radius: 16px;
`;
export const FormItem = styled(Form.Item)`
  display: flex;
  background: #504fd6;
  border-radius: 16px;
  label {
    font-weight: 700;
    color: #fff;
    padding: 10px;
  }
  input {
    border-radius: 16px;
  }
`;
export const InputStyled = styled(Input)`
  width: ${({fullwidth}) => fullwidth === 'true' ? '100%' : '60%'};
  font-size: 20px;
  font-weight: 800;
  color: #fff;
  background: #322e2f;
  border-radius: 5px;
  border: none;
  padding: 0.75rem 0.75rem 0.75rem 1rem;
  &:focus {
    box-shadow: none !important;
  }
`;

export const BtnContribute = styled(Button)`
  position: relative;
  text-align:center;
  /* max-width: 352px; */
  width: 100%;
  height: 55px;
  border-radius: 12px;
  border: none;
  font-weight: 800;
  color: #fff;
  background: #f2bd48;
  background: #f2994a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #f2bd48,
    #f2994a
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #f2bd48,
    #f2994a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  background-size: 150% 150%;
  transition: 0.5s;
  :hover {
    color: #fff;
    background: #f2994a; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #f2bd48,
      #f2994a
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #f2bd48,
      #f2994a
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }
  :focus {
    color: #fff;
    background: #ffd176;
  }
  :before {
    background: #ffd176; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #f2c94c,
      #f2994a
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #ffd176,
      #f2994a
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }
`;

export const CollapseStyled = styled(Collapse)`
  align-items: center;
  background-color: #0D1225;
  border: none;
  border-radius: 16px;
  margin-bottom: 20px;
  .ant-collapse-item {
    border: none;
  }
  .ant-collapse-item > .ant-collapse-header {
    color: #fff;
    height: 70px;
    line-height: 45px;
  }
  .ant-collapse-content {
    background-color: #322e2f;
  }
  .ant-collapse-item:last-child > .ant-collapse-content {
    color: #fff;
    border: none;
    border-radius: 16px;
  }
`